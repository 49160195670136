import TripleAdvantage from './../components/TripleAdvantage'
const WealthtecCatalog = () => (
    <>
        <div style={{backgroundColor: "#204f9c", padding: "70px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>WealthTec offers end-to-end solutions from inception to execution in areas related to Content, Design, Data.</h2>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container" align="center">
            <div align="center" className="uk-width-1-3@m">
                <img src={require("./../img/catalog.png")} style={{maxHeight:"150px"}} />
                <h2 align="center" className="uk-margin-small-top"><b>WealthTec Catalog</b></h2>
                <p className="uk-text-justify">WealthTec is changing how clients manage their products and services by
                    enhancing just about every important function that goes in the making of
                    the product. We are paving the way for clients to achieve success by
                    reaching out to their stakeholders more efficiently and innovatively.</p>
            </div>
        </div>
        <TripleAdvantage />
    </>
)
export default WealthtecCatalog;
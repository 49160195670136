import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from "./pages/Home";
import WealthtecWay from "./pages/WealthtecWay";
import WealthtecCatalog from "./pages/WealthtecCatalog"
import WealthtecAssurance from "./pages/WealthtecAssurance"
import WhatWeDo from "./pages/WhatWeDo"
import Overview from "./pages/Overview"
import History from "./pages/History"
import Team from "./pages/Team"
import Clientele from "./pages/Clientele"
import Contact from "./pages/Contact"
import NoPage from "./pages/NoPage"

function App() {
  return (
    <div className="App container">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="wealthtec-way" element={<WealthtecWay />} />
          <Route path="wealthtec-catalog" element={<WealthtecCatalog />} />
          <Route path="wealthtec-assurance" element={<WealthtecAssurance />} />
          <Route path="what-we-do" element={<WhatWeDo />} />
          <Route path="overview" element={<Overview />} />
          <Route path="history" element={<History />} />
          <Route path="our-team" element={<Team />} />
          <Route path="clientele" element={<Clientele />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

export default function TripleAdvantage(props){
    return (
        <div style={{backgroundColor: '#f2f2f2', padding: "50px 0"}} className={props.nomargin ? "" : "uk-margin-large-top"}>
            <div className="uk-container">
                <center><h2 className="heading">WealthTec's Triple Advantage</h2></center>
                <br/>
                <div className="uk-grid-large uk-child-width-1-3@s uk-text-center" uk-grid="true">
                    <div>
                        <img src={require("./../img/content-150x150.png")} style={{maxHeight:"150px"}} />
                        <h2 align="center" className="uk-margin-small-top"><b>Content</b></h2>
                        <p className="uk-text-center">Powering content and digital media initiatives for clients.</p>
                    </div>
                    <div>
                        <img src={require("./../img/design-150x150.png")} style={{maxHeight:"150px"}} />
                        <h2 align="center" className="uk-margin-small-top"><b>Design</b></h2>
                        <p className="uk-text-center">Making design work for clients by generating templates.</p>
                    </div>
                    <div>
                        <img src={require("./../img/data.png")} style={{maxHeight:"150px"}} />
                        <h2 align="center" className="uk-margin-small-top"><b>Data</b></h2>
                        <p className="uk-text-center">Doing the math for the products, marketing and compliance functions.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import TripleAdvantage from './../components/TripleAdvantage'
import background from "./../img/clients-bg.jpg"

const Clientele = () => (
    <>
         <div className='uk-position-relative' style={{backgroundImage: `url(${background})`, padding: "150px 0"}}>
            <div className="uk-overlay uk-position-cover"></div>
            <div className="uk-overlay uk-position-center uk-light">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>WealthTec works closely with about every participant in the financial services space.</h2>
            </div>
        </div>

        <div style={{backgroundColor: "#204f9c", padding: "100px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>Our clientele includes Asset Managers, Mutual Funds, Life Insurers, Health Insurers, NBFCs, Brokerages, Wealth Managers and Banks.</h2>
            </div>
        </div>

        <TripleAdvantage nomargin />
    </>
)
export default Clientele;
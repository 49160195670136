import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios'

export default function Footer(){

    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onChange" });
    
    const onSubmit = data => {
        setSubmitting(true)
        axios.post('https://ewealthtec.com/sendmail.php', data, {headers: {'Content-Type':'application/x-www-form-urlencoded'}}).then(response => {
            setSubmitting(false)
            setSuccess(true)
            reset()
        }).catch(err => {
            setSubmitting(false)
            alert(err.message)
        })
    }

    return (
        <div style={{backgroundColor: "#204f9c"}} className="uk-padding-small">
            <div className="uk-container">
                <div className="uk-padding-small"></div>
                <div className="uk-child-width-1-2@m uk-child-width-expand@s" uk-grid="true">
                    <div>
                        <h3 className="uk-text-white">Write to us</h3>
                        <form className="uk-form-stacked footer-form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="uk-margin">
                                <label className="uk-form-label">Name</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-stacked-text" type="text" {...register("name", {required: "This field is required!", maxLength: 80})} />
                                    {errors.name && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.name.message}</p>}
                                </div>
                            </div>
                        
                            <div className="uk-margin">
                                <label className="uk-form-label">Email Address</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-stacked-text" type="text" {...register("email", {required: "This field is required!", pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: "Invalid email address"
                                    }})} />
                                    {errors.email && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.email.message}</p>}
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-select">Mobile</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" name="mobile" id="mobile" type="phone" {...register("mobile", {required: "This field is required!", minLength: {
                                        value: 10,
                                        message: "Mobile must be 10 digits"
                                    }, maxLength: {
                                        value: 10,
                                        message: "Mobile must be 10 digits"
                                    }, pattern: {
                                        value: /^\d+$/,
                                        message: 'Invalid phone number'
                                    }})} />
                                    {errors.mobile && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.mobile.message}</p>}
                                </div>
                            </div>
                        
                            <div className="uk-margin">
                                <div className="uk-form-label">Message</div>
                                <div className="uk-form-controls">
                                    <textarea  rows="5" className="uk-textarea" id="form-stacked-text" {...register("message", {required: "This field is required!"})}></textarea>
                                    {errors.message && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.message.message}</p>}
                                </div>
                            </div>

                            <div className="uk-margin">
                                <button type="submit" disabled={submitting} className="uk-button uk-button-default"><span uk-icon="icon: mail"></span> &nbsp;SEND MESSAGE</button>
                                {submitting && <div uk-spinner="true" className='uk-margin-small-left uk-text-white'></div>}
                            </div>

                            {success && <div className='uk-alert-success' uk-alert="true">
                                <a className="uk-alert-close" uk-close="true"></a>
                                Form has been submitted successfully.
                            </div>}
                        
                        </form>
                    </div>
                    <div>
                        <h3 className="uk-text-white">Contact info</h3>

                        <h5 className="uk-text-white" style={{marginBottom: "10px"}}><b>WealthTec</b></h5>
                        <p className="uk-margin-remove uk-text-white"><span uk-icon="icon: location"></span> &nbsp;B-103, Baytun Nur, CST Road, Near Kurla Depot, Opp Shanti Niketan Society, Kanakia Zillion, Kurla (W), Mumbai – 400070</p>
                        
                        <h5 className="uk-text-white" style={{marginBottom: "10px"}}><b>Phone</b></h5>
                        <p className="uk-margin-remove uk-text-white"><span uk-icon="icon: receiver"></span> &nbsp;<a className="uk-text-white" href="tel:+91 86550 16125">+91 86550 16125</a> / <a href="tel:+91 72080 06125" className="uk-text-white">+91 72080 06125</a></p>
                        
                        <div className="uk-margin-medium-top">
                            <iframe style={{width: '100%', height: '300px', borderRadius: "20px"}} loading="lazy" src="https://maps.google.com/maps?q=WealthTec%20Inc.%2C%20Kurla%20West%2C%20Kurla%2C%20Mumbai%2C%20Maharashtra&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="WealthTec Inc., Kurla West, Kurla, Mumbai, Maharashtra" aria-label="WealthTec Inc., Kurla West, Kurla, Mumbai, Maharashtra"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import TripleAdvantage from './../components/TripleAdvantage'
import background from "./../img/who-we-are-bg.jpg"

const Team = () => (
    <>
        <div className='uk-position-relative' style={{backgroundImage: `url(${background})`, padding: "150px 0"}}>
            <div class="uk-overlay-primary uk-position-cover"></div>
            <div class="uk-overlay uk-position-center uk-light">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>Our team combines diversity with experience.</h2>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container" align="center">
            <div align="center" className="uk-width-3-5@m">
                <img src={require("./../img/team.png")} style={{maxHeight:"150px"}} />
                <p class="uk-margin-medium-top uk-text-center">WealthTec's team is a combination of diversity and experience in multiple
                        areas like content, design, data and technology. WealthTec was founded by
                        Irfan Rupani who has had stints with Price Waterhouse, Quantum and
                        CRISIL before embarking on his own.</p>
            </div>
        </div>
        <TripleAdvantage />
    </>
)
export default Team;
const Home = () => (
    <>
        <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" uk-slideshow="ratio: 7:2.8; autoplay: true; max-height: 550">
            <ul className="uk-slideshow-items">
                <li>
                    <img src={require("./../img/Wealthtec-Website-02.jpg")} alt="" uk-cover="true" />
                </li>
                <li>
                    <img src={require("./../img/Wealthtec-Website-03.jpg")} alt="" uk-cover="true" />
                </li>
                <li>
                    <img src={require("./../img/Wealthtec-Website-04.jpg")} alt="" uk-cover="true" />
                </li>
                <li>
                    <img src={require("./../img/Wealthtec-Website-05.jpg")} alt="" uk-cover="true" />
                </li>
                <li>
                    <img src={require("./../img/Wealthtec-Website-06.jpg")} alt="" uk-cover="true" />
                </li>
            </ul>
            <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous="true" uk-slideshow-item="previous"></a>
            <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next="true" uk-slideshow-item="next"></a>
        </div>

        <div className="uk-margin-medium-top uk-container">
            <center><h2 className="heading">Why WealthTec</h2></center>
            <p align="center">WealthTec is the agent of innovation WealthTec is changing how clients manage their products <br/>
                and services by enhancing just about every important function that goes in the making of the product.<br/>
                We are paving the way for clients to achieve success by reaching out to their stakeholders more efficiently and innovatively.</p>
        </div>

        <div className="uk-margin-large-top uk-container">
            <center><h2 className="heading">Our Services</h2></center>
            <br/>
            <div className="uk-grid-small uk-child-width-1-4@m uk-child-width-1-2 uk-text-center" uk-grid="true">
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                        <img src={require("./../img/factsheet.jpg")} />
                    </div>
                    <p align="center">Factsheet</p>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                        <img src={require("./../img/data-n-content.jpg")} />
                    </div>
                    <p align="center">Data &amp; Content</p>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                        <img src={require("./../img/design.jpg")} />
                    </div>
                    <p align="center">Design</p>
                </div>
                <div>
                    <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                        <img src={require("./../img/video.jpg")} />
                    </div>
                    <p align="center">Video</p>
                </div>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container">
            <center><h2 className="heading">Our Work</h2></center>
            <br/>
            <div uk-lightbox="true">
                <div className="uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s uk-text-center" uk-grid="true">
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                            <a href={require("./../img/works.jpg")}><img src={require("./../img/works.jpg")} /></a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                            <a href={require("./../img/work-2.jpg")}><img src={require("./../img/work-2.jpg")} /></a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                            <a href={require("./../img/work-3.jpg")}><img src={require("./../img/work-3.jpg")} /></a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                            <a href={require("./../img/work-4.jpg")}><img src={require("./../img/work-4.jpg")} /></a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                            <a href={require("./../img/work-5.jpg")}><img src={require("./../img/work-5.jpg")} /></a>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-padding-remove">
                            <a href={require("./../img/work-6.jpg")}><img src={require("./../img/work-6.jpg")} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{backgroundColor: '#f2f2f2', padding: '50px 0'}} className="uk-margin-large-top">
            <div className="uk-container">
                <div uk-grid="true" className="uk-grid-small uk-child-width-1-5">
                    <div><img src={require("./../img/kotak-logo.jpg")} /></div>
                    <div><img src={require("./../img/mirae-asset-logo.jpg")} /></div>
                    <div><img src={require("./../img/sbi-mutual-funds-logo.jpg")} /></div>
                    <div><img src={require("./../img/uti-mutual-funds-logo.jpg")} /></div>
                    <div><img src={require("./../img/tata-mutual-funds-logo.jpg")} /></div>
                </div>
            </div>
        </div>
    </>
)
export default Home;
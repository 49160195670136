import {menuItems} from "./menuItems";
import { NavLink } from 'react-router-dom'; 
import UIkit from 'uikit'

const Header = () => {

  const closeSideNav = () => {
    UIkit.offcanvas('#sidenav').hide();
  }

  return (
    <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky" className="uk-box-shadow-small">
      <nav className="uk-navbar-container" style={{backgroundColor: 'white', padding: '15px 0'}}>
          <div className="uk-container">
              <div uk-navbar="true">
                  <div className="uk-navbar-left">
                      <a className="uk-logo" href="/">
                          <img src={require('./../img/logo.png')} style={{maxWidth: '138px'}} className="uk-logo" />
                      </a>
                  </div>
                  <div className="uk-navbar-center uk-visible@m">
                      <ul className="uk-navbar-nav">
                          {menuItems.map((item, idx) => {
                            return (
                              <li key={idx}>
                               <NavLink to={item.url}>{item.title} {typeof item.children != "undefined" && <span uk-nav-parent-icon="true"></span>}</NavLink>
                                {typeof item.children != 'undefined' && 
                                  <div className="uk-navbar-dropdown">
                                    <ul className="uk-nav uk-navbar-dropdown-nav">
                                    {item.children.map((item, index) => (
                                      <li key={index}><NavLink to={item.url}>{item.title}</NavLink></li>
                                    ))}
                                    </ul>
                                  </div>    
                                }
                              </li>
                            )}
                          )}
                      </ul>
                  </div>
                  <div className="uk-navbar-right uk-flex-column uk-margin-small-top" style={{gap: '5px'}}>
                      <a href="tel:+91 8655 216 125" className="uk-text-secondary uk-visible@l">
                          <span uk-icon="icon: receiver"></span> +91 8655 216 125
                      </a>
                      <a href="mailto:support@wealthtec.in" className="uk-text-secondary uk-visible@l">
                          <span uk-icon="icon: mail"></span> support@wealthtec.in
                      </a>
                      <a href="#" className="uk-navbar-toggle uk-hidden@m" uk-navbar-toggle-icon="true" uk-toggle="target: #sidenav"></a>
                  </div>
                  <div id="sidenav" uk-overlay="true" uk-offcanvas="flip: false" className="uk-offcanvas">
                      <div className="uk-offcanvas-bar uk-background-light">
                          <img src={require('./../img/logo.png')} style={{maxWidth: "138px"}} className="uk-logo" />
                          <hr style={{borderColor: "#dedede"}} />
                          <ul className="uk-nav-divider uk-margin-large-top" uk-nav="true">
                            {menuItems.map((item, idx) => {
                              return (
                                <li key={idx} className={typeof item.children != "undefined" ? "uk-parent" : ""}>
                                  <NavLink onClick={() => typeof item.children == "undefined" ? closeSideNav() : null} to={item.url}>{item.title} {typeof item.children != "undefined" && <span uk-nav-parent-icon="true"></span>}</NavLink>
                                  {typeof item.children != 'undefined' && 
                                    <ul className="uk-nav-sub">
                                      {item.children.map((item, index) => (
                                        <li key={index}><NavLink to={item.url} onClick={() => closeSideNav()}>{item.title}</NavLink></li>
                                      ))}
                                    </ul>
                                  }
                                </li>
                              )}
                            )}
                          </ul>
                          <hr />
                          <a href="tel:+91 8655 216 125" className="uk-text-gray">
                              <span uk-icon="icon: receiver"></span> +91 8655 216 125
                          </a>
                          <br/>
                          <a href="mailto:support@wealthtec.in" className="uk-float-left uk-margin-small-top uk-text-gray">
                              <span uk-icon="icon: mail"></span> support@wealthtec.in
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </nav>
  </div>
  );
};

export default Header;
import React from "react";
import axios from 'axios'
const WhatWeDo = () => {

    const [data, setData] = React.useState({})
    React.useEffect(() => {
        axios.get('https://ewealthtec.com/jsonhandler.php')
        .then(data => setData(data.data))
        .catch(rejected => {
            console.log(rejected);
        });
    },[])

    return (
    <>
        <div style={{backgroundColor: "#204f9c", padding: "100px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>We are a highly skilled agency offering creative solutions to clients in their communication with consumers, investors, distributors and regulators</h2>
            </div>
        </div>

        <div style={{backgroundColor: "#f2f2f2", padding: "50px 0"}}>
            <div className="uk-container">
                <div className="uk-grid-small uk-child-width-1-2" uk-grid="true">
                    <div>
                        <h2 className="uk-margin-small"><b>Content</b></h2>
                        <p className="uk-margin-small-top">Powering content and digital media initiatives for clients across</p>
                        <div>
                            <ul className="uk-list">
                                <li><span uk-icon="world"></span> Websites</li>
                                <li><span uk-icon="pencil"></span> Blogs</li>
                                <li><span uk-icon="eye-slash"></span> Ghost Articles</li>
                                <li><span uk-icon="file-text"></span> Newsletters</li>
                                <li><span uk-icon="file-pdf"></span> Product Brochures</li>
                                <li><span uk-icon="album"></span> Presentations</li>
                                <li><span uk-icon="file-edit"></span> Factsheets</li>
                            </ul>
                        </div>
                    </div>
                    <div align="center">
                        <img src={require("./../img/content.png")} style={{maxHeight:"350px"}} />
                    </div>
                </div>
            </div>
        </div>

        <div className="uk-container uk-margin-large-top">
            <div className="uk-grid-small uk-child-width-1-2" uk-grid="true">
                <div align="center">
                    <img src={require("./../img/design.png")} style={{maxHeight:"250px"}} />
                </div>
                <div>
                    <h2 className="uk-margin-small"><b>Design</b></h2>
                    <p className="uk-margin-small-top">Making design work for clients by generating templates and updating content in</p>
                    <div>
                        <ul className="uk-list">
                            <li><span uk-icon="file-edit"></span> Factsheets</li>
                            <li><span uk-icon="file-pdf"></span> Product One Pagers</li>
                            <li><span uk-icon="file-text"></span> Newsletters</li>
                            <li><span uk-icon="album"></span> Presentations</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div style={{backgroundColor: "#f2f2f2", padding: "50px 0"}} className="uk-margin-large-top">
            <div className="uk-container">
                <div className="uk-grid-small uk-child-width-1-2" uk-grid="true">
                    <div>
                        <h2 className="uk-margin-small"><b>Data</b></h2>
                        <p className="uk-margin-small-top">Doing the math for the products, marketing and compliance functions with</p>
                        <div>
                            <ul className="uk-list">
                                <li><span uk-icon="database"></span> Aggregation</li>
                                <li><span uk-icon="hashtag"></span> Calculation</li>
                                <li><span uk-icon="file-edit"></span> Analysis</li>
                            </ul>
                        </div>
                    </div>
                    <div align="center">
                        <img src={require("./../img/data.png")} style={{maxHeight:"250px"}} />
                    </div>
                </div>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container">
            <ul className="uk-flex-center uk-subnav uk-tab" uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                <li className="uk-active"><a href="#"><b>Videos</b></a></li>
                <li><a href="#"><b>Facts Sheet (PDF)</b></a></li>
            </ul>
            <ul className="uk-switcher uk-margin">
                <li>
                    <div className="uk-child-width-1-3@m uk-grid-small" uk-grid="true">
                        {Object.keys(data).length > 0 && typeof data.video != "undefined" && data.video.map((item,idx) => (
                            <div key={idx} align="center">
                                <iframe width="340" height="200" src={item} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        ))}
                    </div>
                </li>
                <li>
                    <div className="uk-child-width-1-5@m uk-child-width-1-3@s" uk-grid="true">
                        {Object.keys(data).length > 0 && typeof data.pdf != "undefined" && data.pdf.map((item,idx) => (
                            <div align="center" key={idx}>
                                <a title={item.title} target="_blank" href={item.url}>
                                    <img src={require("./../img/pdf_icon.png")} style={{maxHeight:"150px"}} />
                                    <h4 className="uk-margin-remove">{item.title}</h4>
                                </a>
                            </div>
                        ))}
                    </div>
                </li>
            </ul>
            <br/><br/><br/>
        </div>
    </>
)}
export default WhatWeDo;
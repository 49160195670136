import React, { useState } from 'react';
import background from "./../img/contact-us-bg.jpg"
import { useForm } from 'react-hook-form';
import axios from 'axios'

const Contact = () => {
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onChange" });

    const onSubmit = data => {
        setSubmitting(true)
        axios.post('https://ewealthtec.com/sendmail.php', data, {headers: {'Content-Type':'application/x-www-form-urlencoded'}}).then(response => {
            setSubmitting(false)
            setSuccess(true)
            reset()
        }).catch(err => {
            setSubmitting(false)
            alert(err.message)
        })
    }

    return (
        <>
            <div className='uk-position-relative' style={{backgroundImage: `url(${background})`, padding: "180px 0"}}>
                <div className="uk-overlay uk-position-cover"></div>
                <div className="uk-overlay uk-position-center uk-light">
                    <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>Contact Us</h2>
                </div>
            </div>

            <div style={{backgroundColor: "#204f9c", padding: "70px 0"}}>
                <div className="uk-container">
                    <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>If you are curious about how we can help you achieve the best for your organization you only have to pick up the phone, write an email or walk into our office.</h2>
                </div>
            </div>

            <div className="uk-container uk-margin-large">
                <div className="uk-grid-large" uk-grid="true">
                    <div className="uk-width-2-5@m uk-width-1-2@s">
                        <form className="uk-form-stacked contact-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-text">Name</label>
                                <div className="uk-form-controls">
                                    <input  className="uk-input" id="name" name="name" type="text" {...register("name", {required: "This field is required!", maxLength: 80})} />
                                    {errors.name && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.name.message}</p>}
                                </div>
                            </div>
                        
                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-select">Email Address</label>
                                <div className="uk-form-controls">
                                    <input  className="uk-input" id="email" name="email" type="email" {...register("email", {required: "This field is required!", pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: "Invalid email address"
                                    }})} />
                                    {errors.email && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.email.message}</p>}
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-select">Mobile</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" name="mobile" id="mobile" type="phone" {...register("mobile", {required: "This field is required!", minLength: {
                                        value: 10,
                                        message: "Mobile must be 10 digits"
                                    }, maxLength: {
                                        value: 10,
                                        message: "Mobile must be 10 digits"
                                    }, pattern: {
                                        value: /^\d+$/,
                                        message: 'Invalid phone number'
                                    }})} />
                                    {errors.mobile && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.mobile.message}</p>}
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-select">Subject</label>
                                <div className="uk-form-controls">
                                    <select className="uk-select" id="subject" name="subject" {...register("subject")}>
                                        <option value="General Enquiry">General Enquiry</option>
                                        <option value="Technical Support">Technical Support</option>
                                        <option value="Feedback">Feedback</option>
                                    </select>
                                </div>
                            </div>
                        
                            <div className="uk-margin">
                                <div className="uk-form-label">Message</div>
                                <div className="uk-form-controls">
                                    <textarea  rows="5" className="uk-textarea" id="form-stacked-text" {...register("message", {required: "This field is required!"})}></textarea>
                                    {errors.message && <p className='uk-margin-remove-bottom uk-margin-small-top uk-text-danger'><span uk-icon='icon: warning'></span> {errors.message.message}</p>}
                                </div>
                            </div>

                            <div className="uk-margin">
                                <button type='submit' disabled={submitting} className="uk-button uk-button-primary"><span uk-icon="icon: mail"></span> &nbsp;SEND MESSAGE</button>
                                {submitting && <div uk-spinner="true" className='uk-margin-small-left'></div>}
                            </div>

                            {success && <div className='uk-alert-success' uk-alert="true">
                                <a className="uk-alert-close" uk-close="true"></a>
                                Form has been submitted successfully.
                            </div>}
                        
                        </form>
                    </div>
                    <div className="uk-width-3-5@m uk-width-1-2@s">
                        <h2 align="center" className="uk-margin-small-top"><b>Our Office Address</b></h2>
                        <p className="uk-margin-remove"><span uk-icon="icon: location"></span> &nbsp;B-103, Baytun Nur, CST Road, Near Kurla Depot, Opp Shanti Niketan Society, Kanakia Zillion, Kurla (W), Mumbai – 400070</p>
                            
                        <p className="uk-margin-small"><span uk-icon="icon: receiver"></span> &nbsp;<a href="tel:+91 86552 16125">+91 86552 16125</a> / <a href="tel:+91 86550 16125">+91 86550 16125</a> / <a href="tel:+91 72080 06125">+91 72080 06125</a></p>

                        <p className="uk-margin-small"><span uk-icon="icon: mail"></span> &nbsp;<a href="mailto:support@wealthtec.in">support@wealthtec.in</a></p>
                        
                        <div className="uk-margin-medium-top">
                            <iframe style={{width: "100%", height: "300px", borderRadius: "20px"}} loading="lazy" src="https://maps.google.com/maps?q=WealthTec%20Inc.%2C%20Kurla%20West%2C%20Kurla%2C%20Mumbai%2C%20Maharashtra&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="WealthTec Inc., Kurla West, Kurla, Mumbai, Maharashtra" aria-label="WealthTec Inc., Kurla West, Kurla, Mumbai, Maharashtra"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;
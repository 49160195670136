import TripleAdvantage from './../components/TripleAdvantage'
const WealthtecWay = () => (
    <>
        <div style={{backgroundColor: "#204f9c", padding: "70px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>Knowledge, technical finesse, solid production know-how and reliable delivery are the four reasons why asset managers choose WealthTec</h2>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container">
            <center><h2 className="heading">Our Services</h2></center>
            <br/>
            <div className="uk-grid-large uk-child-width-1-3@s uk-text-center" uk-grid="true">
                <div>
                    <img src={require("./../img/efficient.png")} style={{maxHeight:"250px"}} />
                    <h2 align="center" className="uk-margin-small-top"><b>Efficient</b></h2>
                    <p className="uk-text-center">Experience in the financial services domain gives us the efficiency edge bringing you time and cost savings.</p>
                </div>
                <div>
                    <img src={require("./../img/timely.png")} style={{maxHeight:"250px"}} />
                    <h2 align="center" className="uk-margin-small-top"><b>Timely</b></h2>
                    <p className="uk-text-center">We work hard so you don’t have to. Our team ensures deadlines are met with delivery ahead of timelines.</p>
                </div>
                <div>
                    <img src={require("./../img/innovative.png")} style={{maxHeight:"250px"}} />
                    <h2 align="center" className="uk-margin-small-top"><b>Innovative</b></h2>
                    <p className="uk-text-center">We are nothing if not innovative.</p>
                </div>
            </div>
        </div>
        <TripleAdvantage />
    </>
)
export default WealthtecWay;
export const menuItems = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Why WealthTec',
    url: '/wealthtec-way',
    children: [
      {
        title: 'WealthTec Way',
        url: '/wealthtec-way'
      },
      {
        title: 'WealthTec Catalog',
        url: '/wealthtec-catalog'
      },
      {
        title: 'WealthTec Assurance',
        url: '/wealthtec-assurance'
      }
    ]
  },
  {
    title: 'What We Do',
    url: '/what-we-do',
  },
  {
    title: 'Who We Are',
    url: '/overview',
    children: [
      {
        title: 'Overview',
        url: '/overview'
      },
      {
        title: 'History',
        url: '/history'
      },
      {
        title: 'Our Team',
        url: '/our-team'
      }
    ]
  },
  {
    title: 'Clientele',
    url: '/clientele',
  },
  {
    title: 'Contact',
    url: '/contact',
  },
];
import TripleAdvantage from './../components/TripleAdvantage'
const Overview = () => (
    <>
        <div style={{backgroundColor: "#204f9c", padding: "70px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>WealthTec is a gateway to new possibilities.</h2>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container" align="center">
            <div align="center" className="uk-width-3-5@m">
                <img src={require("./../img/overview.png")} style={{maxHeight:"100px"}} />
                <h2 align="center" className="uk-margin-medium-top"><b>WealthTec is the agent of innovation</b></h2>
                <p className="uk-text-center">WealthTec offers end-to-end solutions from inception to execution in areas<br/> related to content, design, writing, marketing, products, templates, website<br/> designing, data crunching.</p>
            </div>
        </div>
        <TripleAdvantage />
    </>
)
export default Overview;
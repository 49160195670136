import TripleAdvantage from './../components/TripleAdvantage'
import background from "./../img/why-wealthtec-bg.jpg"

const WealthtecAssurance = () => (
    <>
        <div style={{backgroundImage: `url(${background})`, backgroundAttachment: 'fixed', padding: "100px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>A typical WealthTec customer is a member of the marketing, products, sales, technology, corporate communications team of a financial services company.</h2>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container">
            <center><h2 className="heading">WealthTec Assurance</h2></center>
            <br/>
            <div className="uk-grid-large uk-child-width-1-4@s uk-text-center" uk-grid="true">
                <div>
                    <img src={require("./../img/knowledge.png")} style={{maxHeight:"200px"}} />
                    <h3 align="center" className="uk-margin-small"><b>Knowledge</b></h3>
                    <p className="uk-text-center uk-margin-small-top">Solid grip on all critical parts of the business.</p>
                </div>
                <div>
                    <img src={require("./../img/technical.png")} style={{maxHeight:"200px"}} />
                    <h3 align="center" className="uk-margin-small"><b>Technical Finesse</b></h3>
                    <p className="uk-text-center uk-margin-small-top">Handling difficult and important tasks with skill.</p>
                </div>
                <div>
                    <img src={require("./../img/solid-production.png")} style={{maxHeight:"200px"}} />
                    <h3 align="center" className="uk-margin-small"><b>Solid Production</b></h3>
                    <p className="uk-text-center uk-margin-small-top">Firm handle on production workflow.</p>
                </div>
                <div>
                    <img src={require("./../img/reliable-delivery.png")} style={{maxHeight:"200px"}} />
                    <h3 align="center" className="uk-margin-small"><b>Reliable Delivery Link</b></h3>
                    <p className="uk-text-center uk-margin-small-top">No compromise no-excuse approach to delivery.</p>
                </div>
            </div>
        </div>
        <TripleAdvantage />
    </>
)
export default WealthtecAssurance;
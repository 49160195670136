import TripleAdvantage from './../components/TripleAdvantage'
const History = () => (
    <>
        <div style={{backgroundColor: "#204f9c", padding: "70px 0"}}>
            <div className="uk-container">
                <h2 className="uk-text-center uk-text-white" style={{lineHeight: "50px"}}>Ours is a journey from zero to zeroing in.</h2>
            </div>
        </div>

        <div className="uk-margin-large-top uk-container" align="center">
            <div align="center" className="uk-width-3-5@m">
                <img src={require("./../img/history.png")} style={{maxHeight:"180px"}} />
                <h2 align="center" className="uk-margin-medium-top"><b>WealthTec is the agent of innovation</b></h2>
                <p className="uk-text-center">Our journey began in 2010 guided by individuals with decades-long
                        experience in financial services, research, content and marketing.
                        The clear goal that we continue to pursue to this day was to make a
                        difference to the execution and delivery model of the financial services product..</p>
            </div>
        </div>
        <TripleAdvantage />
    </>
)
export default History;